<template>
  <div class="coin">
    <!-- Header -->
    <section class="head">
      <div class="bg"></div>
      <div class="left">
        <a @click="goTo('/home')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <div class="center">
        <span>{{ stamp.stampCardName }}</span>
        <span>{{ stamp.numOfStamps || 0 }}點</span>
      </div>
      <div class="right"></div>
    </section>
    <section class="main">
      <div class="pages">
        <a
          v-if="isMM"
          :class="['page', { active: tab === 'collected' }]"
          @click.prevent="tab = 'collected'"
        >
          <span>集點紀錄</span>
        </a>
        <a
          :class="['page', { active: tab === 'redeemed' }]"
          @click.prevent="tab = 'redeemed'"
        >
          <span>兌換紀錄</span>
        </a>
      </div>
      <a class="dateRange" @click.prevent="timeFilterShow">
        <span>{{ displayedTimeFilterOption }}</span>
        <font-awesome-icon icon="fa-regular fa-calendar"></font-awesome-icon>
      </a>

      <div v-if="isInitialized && !transactions.length" class="item">
        <div class="left">
          <span>無紀錄</span>
        </div>
      </div>
      <div
        v-else-if="isInitialized"
        class="item"
        v-for="(transaction, index) in transactions"
        :key="'transaction' + index"
      >
        <div class="left">
          <span>{{ displayText(transaction) }}</span>
          <span class="content">{{ transaction.remark }}</span>
          <span class="content">{{ tsToDatetime(transaction.createdAt) }}</span>
        </div>
        <div class="right">
          <span :class="{ negative: transaction.amount < 0 }"
            >{{ transaction.amount > 0 ? '+' : ''
            }}{{ transaction.amount }}</span
          >
        </div>
      </div>
    </section>
    <!-- 日期popup -->
    <section
      id="popDateRange"
      class="pop dateRange"
      @click.prevent="timeFilterHide"
    >
      <div class="popBody" @click.stop>
        <a class="close" @click.prevent="timeFilterHide"
          ><font-awesome-icon
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon
        ></a>
        <span class="title">日期</span>
        <div class="tabs">
          <a
            :class="{ active: timeFilterOption === 'Weekly' }"
            @click.prevent="changeTimeFilterOption('Weekly')"
            ><span>近一週</span></a
          >
          <a
            :class="{ active: timeFilterOption === 'Monthly' }"
            @click.prevent="changeTimeFilterOption('Monthly')"
            ><span>近一個月</span></a
          >
          <a
            :class="{ active: timeFilterOption === 'Quarterly' }"
            @click.prevent="changeTimeFilterOption('Quarterly')"
            ><span>近三個月</span></a
          >
          <a
            :class="{ active: timeFilterOption === 'Customize' }"
            @click.prevent="changeTimeFilterOption('Customize')"
            ><span>自訂日期</span></a
          >
        </div>
        <div class="dateRange" v-show="timeFilterOption == 'Customize'">
          <input
            type="date"
            name="startDate"
            v-model="selectStart"
            @change="selectStartChange"
          />
          <span>-</span>
          <input
            type="date"
            name="endDate"
            v-model="selectEnd"
            @change="selectEndChange"
          />
        </div>
        <div class="buttons">
          <button @click.prevent="timeFilterHide">
            <span>取消</span>
          </button>
          <button @click.prevent="timeFilterConfirm">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';

export default {
  name: 'StampCardRecord',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      stampCardId: this.$route.params.stampCardId,
      stamp: {},
      transactions: [],
      originTransactions: {},
      tab: this.$route.query.shop_id ? 'collected' : 'redeemed',
      timeFilterStart: null,
      timeFilterEnd: null,
      timeFilterOption: 'Monthly',
      displayedTimeFilterOption: '近一個月',
      selectStart: this.tsToDate(
        Math.floor(Date.now() / 1000) - 1 * 60 * 60 * 24 * 30
      ),
      selectEnd: this.tsToDate(Math.floor(Date.now() / 1000)),
      selectStartErr: false,
      selectEndErr: false,
      isInitialized: false,
    };
  },
  mounted() {
    this.getStamp();
    this.getHistory();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    merchantId() {
      return this.user.user._json.merchantId || this.userInfo.merchantId;
    },
    isMM () {
      // 有shop_id就MM，沒有是BM
      return this.$route.query.shop_id;
    }
  },
  watch: {
    tab() {
      this.filterByTypeHandler();
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm:ss');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    callGetStampAPI() {
      let config = {
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards/${this.stampCardId}/collector-status?uuid=${this.user.userId}`,
      };
      return this.$http(config);
    },
    getStamp() {
      this.callGetStampAPI()
        .then(res => {
          this.stamp = res.data;
        })
        .catch(err => {
          console.log(err);
          this.goTo('/error');
        });
    },
    callGetHistoryAPI(start, end) {
      let endTime = end || Math.floor(Date.now() / 1000);
      let startTime = start || endTime - 1 * 60 * 60 * 24 * 30;

      let config = {
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards/${this.stampCardId}/collector-transactions?uuid=${this.user.userId}&from=${startTime}&to=${endTime}`,
      };
      return this.$http(config);
    },
    getHistory(start, end) {
      this.callGetHistoryAPI(start, end)
        .then(res => {
          console.log('getHistory res data:', res.data);
          this.originTransactions = JSON.parse(JSON.stringify(res.data));
          this.filterByTypeHandler();
        })
        .catch(err => {
          console.log('getHistory err:', err);
        })
        .finally(() => (this.isInitialized = true));
    },
    filterByTypeHandler() {
      switch (this.tab) {
        case 'collected':
          this.transactions = this.originTransactions.transactions
            .filter(t => t.transactionType === 'collect-stamps')
            .sort((a, b) => b.createdAt - a.createdAt);
          break;
        case 'redeemed':
          this.transactions = this.originTransactions.transactions
            .filter(t => t.transactionType === 'redeem-reward')
            .sort((a, b) => b.createdAt - a.createdAt);
          break;
        default:
          break;
      }
    },
    timeFilterShow() {
      popDateRange.style.display = 'flex';
    },
    timeFilterHide() {
      popDateRange.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        popDateRange.style.display = 'none';
        popDateRange.children[0].style.animationName = 'popup';
      }, 500);
    },
    timeFilterConfirm() {
      if (this.timeFilterOption == 'Customize') {
        this.selectStartErr = this.selectStart ? false : true;
        this.selectEndErr = this.selectEnd ? false : true;
        if (this.selectStartErr || this.selectEndErr) return;
      }
      this.changeTimeFilterValue();
      let start = this.timeFilterStart;
      let end = this.timeFilterEnd;

      this.getHistory(start, end);
      this.timeFilterHide();
    },
    changeTimeFilterOption(option) {
      this.timeFilterOption = option;
    },
    changeTimeFilterValue() {
      switch (this.timeFilterOption) {
        case 'Weekly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 7);
          this.displayedTimeFilterOption = '近一週';
          break;
        case 'Monthly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 30);
          this.displayedTimeFilterOption = '近一個月';
          break;
        case 'Quarterly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 90);
          this.displayedTimeFilterOption = '近三個月';
          break;
        case 'Customize':
          this.timeFilterEnd =
            this.dateEndToTs(this.selectEnd) > Date.now() / 1000
              ? Date.now() / 1000
              : this.dateEndToTs(this.selectEnd);
          this.timeFilterStart = this.dateStartToTs(this.selectStart);
          this.displayedTimeFilterOption =
            this.selectStart.replaceAll('-', '/') +
            ' - ' +
            this.selectEnd.replaceAll('-', '/');
          break;
        default:
          break;
      }
    },
    dateToTs(date) {
      return new Date(date).getTime() / 1000;
    },
    dateStartToTs(date) {
      return new Date(date).setHours(0, 0, 0, 0) / 1000;
    },
    dateEndToTs(date) {
      return new Date(date).setHours(23, 59, 59, 999) / 1000;
    },
    getStartTs(endTs, days) {
      return (
        new Date(endTs * 1000).setHours(0, 0, 0, 0) / 1000 -
        1 * 60 * 60 * 24 * days
      );
    },
    selectStartChange() {
      this.selectStartErr = false;
    },
    selectEndChange() {
      this.selectEndErr = false;
    },
    displayText(transaction) {
      switch (transaction.transactionType) {
        case 'collect-stamps':
          return '消費集點';
        case 'redeem-reward':
          return `兌換${transaction.rewardName}`;
        default:
          return transaction.transactionType;
      }
    },
  },
};
</script>
