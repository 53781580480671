<template>
  <div class="coin">
    <div v-if="isLoading" class="loading transparent"><div> <div></div></div></div>
    <!-- Header -->
    <section class="head">
      <div class="bg"></div>
      <div class="left">
        <a @click="goTo('/home')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>{{ pointName }}</span>
      <img src="@/assets/icon/mPoint.svg" />
      <span class="content">{{ displayedPointBalance }}</span>
      <div class="right"></div>
    </section>
    <section class="main">
      <div class="pages">
        <a
          :class="['page', { active: tab === 'got' }]"
          @click.prevent="tab = 'got'"
        >
          <span>獲得</span>
        </a>
        <a
          :class="['page', { active: tab === 'used' }]"
          @click.prevent="tab = 'used'"
        >
          <span>使用</span>
        </a>
        <a
          :class="['page', { active: tab === 'exp' }]"
          @click.prevent="tab = 'exp'"
        >
          <!-- <span>期限</span> -->
          <span>即將到期</span>
        </a>
      </div>
      <a v-if="tab !== 'exp'" class="dateRange" @click.prevent="timeFilterShow">
        <span>{{ displayedTimeFilterOption }}</span>
        <font-awesome-icon icon="fa-regular fa-calendar"></font-awesome-icon>
      </a>
      <fragment v-if="tab === 'exp'">
        <div v-if="!isLoading && !displayedPointPeriods.length" class="item">
          <div class="left">
            <span>無紀錄</span>
          </div>
        </div>
        <div
          v-else
          class="item"
          v-for="(pointPeriod, index) in displayedPointPeriods"
          :key="'point' + index"
        >
          <div class="left">
            <span>{{ tsToDate(pointPeriod.endTs) }}到期</span>
          </div>
          <div class="right">
            <span>{{ pointPeriod.pointBalance }}</span>
            <img src="@/assets/icon/mPoint.svg" />
          </div>
        </div>
      </fragment>
      <fragment v-else>
        <div v-if="!isLoading && !displayedTransactions.length" class="item">
          <div class="left">
            <span>無紀錄</span>
          </div>
        </div>
        <div
          v-else
          class="item"
          v-for="(transaction, index) in displayedTransactions"
          :key="'transaction' + index"
        >
          <div class="left">
            <span>{{ $t(transaction.transactionType) }}</span>
            <span class="content">{{ transaction.note }}</span>
            <span class="content">{{
              tsToDatetime(transaction.transactionTs)
            }}</span>
          </div>
          <div class="right">
            <span :class="pointClass(transaction.entryType)">
              {{ displaySignOfPoint(transaction.entryType)
              }}{{ transaction.pointAmount }}
            </span>
            <img src="@/assets/icon/mPoint.svg" />
          </div>
        </div>
      </fragment>
    </section>
    <!-- 日期popup -->
    <section
      id="popDateRange"
      class="pop dateRange"
      @click.prevent="timeFilterHide"
    >
      <div class="popBody" @click.stop>
        <a class="close" @click.prevent="timeFilterHide"
          ><font-awesome-icon
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon
        ></a>
        <span class="title">日期</span>
        <div class="tabs">
          <a
            :class="{ active: timeFilterOption === 'Weekly' }"
            @click.prevent="changeTimeFilterOption('Weekly')"
            ><span>近一週</span></a
          >
          <a
            :class="{ active: timeFilterOption === 'Monthly' }"
            @click.prevent="changeTimeFilterOption('Monthly')"
            ><span>近一個月</span></a
          >
          <a
            :class="{ active: timeFilterOption === 'Quarterly' }"
            @click.prevent="changeTimeFilterOption('Quarterly')"
            ><span>近三個月</span></a
          >
          <a
            :class="{ active: timeFilterOption === 'Customize' }"
            @click.prevent="changeTimeFilterOption('Customize')"
            ><span>自訂日期</span></a
          >
        </div>
        <div class="dateRange" v-show="timeFilterOption == 'Customize'">
          <input
            type="date"
            name="startDate"
            v-model="selectStart"
            @change="selectStartChange"
          />
          <span>-</span>
          <input
            type="date"
            name="endDate"
            v-model="selectEnd"
            @change="selectEndChange"
          />
        </div>
        <div class="buttons">
          <button @click.prevent="timeFilterHide">
            <span>取消</span>
          </button>
          <button @click.prevent="timeFilterConfirm">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState, mapGetters } from 'vuex';
// import Spinner from '@/components/Spinner.vue';

export default {
  name: 'PointRecord',
  components: {
    // Spinner,
  },
  data() {
    return {
      isLoading: true,
      apiHost: process.env.VUE_APP_API_HOST,
      transactions: {},
      originTransactions: {},
      tab: 'got',
      timeFilterStart: null,
      timeFilterEnd: null,
      timeFilterOption: 'Monthly',
      displayedTimeFilterOption: '近一個月',
      selectStart: this.tsToDate(
        Math.floor(Date.now() / 1000) - 1 * 60 * 60 * 24 * 30
      ),
      selectEnd: this.tsToDate(Math.floor(Date.now() / 1000)),
      selectStartErr: false,
      selectEndErr: false,
      points: [
        {
          pointBalance: 0,
          pointPeriods: [],
        },
      ],
    };
  },
  created() {
    this.getUserPoints()
      .then(getUserPointsRes => {
        console.log('getUserPointsRes: ', getUserPointsRes);
        this.$store.commit('updateUserPoints', getUserPointsRes);
        this.points = JSON.parse(JSON.stringify(this.userPoints));
        return this.getTransactions();
      })
      .then(getTransactionsRes => {
        console.log('getTransactionRes', getTransactionsRes);
        this.transactions = JSON.parse(JSON.stringify(getTransactionsRes));
        this.originTransactions = JSON.parse(
          JSON.stringify(getTransactionsRes)
        );
        this.filterByTypeHandler();
      })
      .catch(err => {
        console.log('err: ', err);
        // this.isLoading = false;
      })
      .finally(()=>{
        this.isLoading = false;
      });
  },
  mounted() {},
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapState('portal', ['portalInfo']),
    ...mapGetters({
      userPoints: 'getUserPoints',
    }),
    merchantId() {
      return this.user.user._json.merchantId || this.userInfo.merchantId;
    },
    pointName() {
      return this.portalInfo?.wallet?.points?.name || this.points?.pointName;
    },
    displayedPointBalance() {
      return this.userPoints?.pointBalance || 0;
    },
    displayedTransactions() {
      return this.transactions?.records || [];
    },
    displayedPointPeriods() {
      if (!this.points?.pointPeriods) return [];
      return this.points.pointPeriods
        .filter(p => p.endTs * 1000 > Date.now())
        .sort((a, b) => a.endTs - b.endTs);
    },
  },
  watch: {
    tab() {
      if (this.tab === 'exp') return;
      this.filterByTypeHandler();
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    pointClass(entryType) {
      return [{ negative: entryType == 'C' }];
    },
    displaySignOfPoint(entryType) {
      return entryType == 'C' ? '-' : '';
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm:ss');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    dummyTransactions() {
      let result = {
        total: 3,
        records: [
          {
            pointAmount: 100,
            entryType: 'D',
            note: '任務完成：2022米哥註冊關卡',
            transactionId: 2987,
            transactionEntityId: 10,
            transactionTs: 1665584359,
            transactionType: 'Activity-Reward',
            merchantOrderId: null,
          },
          {
            pointAmount: 100,
            entryType: 'D',
            note: '任務完成：2022米哥註冊關卡',
            transactionId: 2965,
            transactionEntityId: 10,
            transactionTs: 1665568720,
            transactionType: 'Activity-Reward',
            merchantOrderId: null,
          },
          {
            pointAmount: 10,
            entryType: 'C',
            note: '點數消費',
            transactionId: 2938,
            transactionEntityId: 10,
            transactionTs: 1665558815,
            transactionType: 'Consumption-Discount',
            merchantOrderId: null,
          },
        ],
      };
      let promise = new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
      return promise;
    },
    callTransactionsAPI(start, end) {
      let endTime = end || Math.floor(Date.now() / 1000);
      let startTime = start || endTime - 1 * 60 * 60 * 24 * 30;

      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points/${this.userPoints.pointCode}/ledgers?from_ts=${startTime}&to_ts=${endTime}`,
        headers: {},
      };
      return this.$http(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getTransactions(start, end) {
      return (
        this.callTransactionsAPI(start, end)
          // this.dummyTransactions(start, end)
          .then(res => {
            return res.data;
          })
          .catch(err => {
            console.log('err:', err);
          })
      );
    },
    filterByTypeHandler() {
      switch (this.tab) {
        case 'used':
          this.transactions.records = this.originTransactions.records.filter(
            t => t.entryType === 'C'
          );
          break;
        case 'got':
          this.transactions.records = this.originTransactions.records.filter(
            t => t.entryType === 'D'
          );
          break;
        default:
          break;
      }
    },
    timeFilterShow() {
      popDateRange.style.display = 'flex';
    },
    timeFilterHide() {
      popDateRange.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        popDateRange.style.display = 'none';
        popDateRange.children[0].style.animationName = 'popup';
      }, 500);
    },
    timeFilterConfirm() {
      if (this.timeFilterOption == 'Customize') {
        this.selectStartErr = this.selectStart ? false : true;
        this.selectEndErr = this.selectEnd ? false : true;
        if (this.selectStartErr || this.selectEndErr) return;
      }
      this.changeTimeFilterValue();
      let start = this.timeFilterStart;
      let end = this.timeFilterEnd;

      if (!this.userPoints?.pointCode) return this.timeFilterHide();
      this.isLoading = true;
      this.getTransactions(start, end)
        .then(getTransactionsRes => {
          this.transactions = JSON.parse(JSON.stringify(getTransactionsRes));
          this.originTransactions = JSON.parse(
            JSON.stringify(getTransactionsRes)
          );
          this.filterByTypeHandler();
          // this.timeFilterHide();
        })
        .catch(err => {
          console.log('err: ', err);
          // this.timeFilterHide();
        })
        .finally(() => {
          this.timeFilterHide();
          this.isLoading = false;
        });
    },
    changeTimeFilterOption(option) {
      this.timeFilterOption = option;
    },
    changeTimeFilterValue() {
      switch (this.timeFilterOption) {
        case 'Weekly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 7);
          this.displayedTimeFilterOption = '近一週';
          break;
        case 'Monthly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 30);
          this.displayedTimeFilterOption = '近一個月';
          break;
        case 'Quarterly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.getStartTs(this.timeFilterEnd, 90);
          this.displayedTimeFilterOption = '近三個月';
          break;
        case 'Customize':
          this.timeFilterEnd =
            this.dateEndToTs(this.selectEnd) > Date.now() / 1000
              ? Date.now() / 1000
              : this.dateEndToTs(this.selectEnd);
          this.timeFilterStart = this.dateStartToTs(this.selectStart);
          this.displayedTimeFilterOption =
            this.selectStart.replaceAll('-', '/') +
            ' - ' +
            this.selectEnd.replaceAll('-', '/');
          break;
        default:
          break;
      }
    },
    dateToTs(date) {
      return new Date(date).getTime() / 1000;
    },
    dateStartToTs(date) {
      return new Date(date).setHours(0, 0, 0, 0) / 1000;
    },
    dateEndToTs(date) {
      return new Date(date).setHours(23, 59, 59, 999) / 1000;
    },
    getStartTs(endTs, days) {
      return (
        new Date(endTs * 1000).setHours(0, 0, 0, 0) / 1000 -
        1 * 60 * 60 * 24 * days
      );
    },
    selectStartChange() {
      this.selectStartErr = false;
    },
    selectEndChange() {
      this.selectEndErr = false;
    },
  },
};
</script>
